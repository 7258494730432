import { defineStore } from 'pinia';
import { type Ref, ref } from 'vue';
import { useTimeoutFn } from '@vueuse/core';
import type {
  ToastItem,
  ToastItemWithId,
} from 'flowbite-vue/dist/components/Toast/components/ToastProvider/types';

export type ToasterStore = {
  toasts: Ref<ToastItemWithId[]>;
  addToast: (toast: ToastItem) => string;
  removeToast: (id: string) => boolean; // true if removed, false if not found
  popToast: () => string; // empty '' string if no toast to pop
};

export const useToasterStore = defineStore(
  'toaster',
  function (): ToasterStore {
    const toasts = ref<ToastItemWithId[]>([]);

    const removeToast = (id: string) => {
      const index = toasts.value.findIndex((_) => _.id === id);
      if (index >= 0) toasts.value.splice(index, 1);
      return index >= 0;
    };

    const runRemoveTimeout = (id: string, ms: number) => {
      useTimeoutFn(() => removeToast(id), ms);
    };

    const addToast = (toast: ToastItem) => {
      const id = parseInt(
        (new Date().getTime() * Math.random()).toString()
      ).toString();
      toasts.value.push({
        id,
        ...toast,
      });
      if (toast.time > 0) runRemoveTimeout(id, toast.time);
      return id;
    };

    const popToast = () => {
      if (toasts.value.length === 0) return '';
      const lastId = toasts.value[toasts.value.length - 1].id;
      toasts.value.pop();
      return lastId;
    };

    return {
      toasts,
      addToast,
      popToast,
      removeToast,
    };
  }
);
