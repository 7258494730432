import { defineNuxtPlugin } from '#app';
import type { User } from '~/types/user';

export default defineNuxtPlugin(async (nuxtApp) => {
  const { $sanctumAuth } = nuxtApp;
  const config = useRuntimeConfig();
  // eslint-disable-next-line no-console
  console.info('👣 UserGuiding plugin initializing...', {
    enabled: config.public.userGuiding.enabled,
    environment: config.public.appEnv,
  });

  // Early return if not enabled
  if (!config.public.userGuiding.enabled) {
    // eslint-disable-next-line no-console
    console.info('👣 UserGuiding disabled via config');
    return;
  }

  // eslint-disable-next-line no-console
  console.info('👣 UserGuiding loading script...');

  // Initialize UserGuiding
  (function (g, u, i, d, e, s) {
    g[e] = g[e] || [];
    const f = u.getElementsByTagName(i)[0];
    const k = u.createElement(i);
    k.async = true;
    k.src =
      'https://static.userguiding.com/media/user-guiding-' + s + '-embedded.js';
    f.parentNode.insertBefore(k, f);
    if (g[d]) return;
    const ug = (g[d] = { q: [] });
    ug.c = function (n) {
      return function (...args) {
        ug.q.push([n, args]);
      };
    };
    const m = [
      'previewGuide',
      'finishPreview',
      'track',
      'identify',
      'hideChecklist',
      'launchChecklist',
    ];
    for (let j = 0; j < m.length; j += 1) {
      ug[m[j]] = ug.c(m[j]);
    }
  })(
    window,
    document,
    'script',
    'userGuiding',
    'userGuidingLayer',
    config.public.userGuiding.id,
  );

  // Get user data
  try {
    const user: User = await $sanctumAuth.getUser();
    if (user) {
      onNuxtReady(() => {
        if (window.userGuiding) {
          // eslint-disable-next-line no-console
          console.info(`👣 UserGuiding identify - ${user.email}`, {
            userId: !!user.id,
            hasLandlord: !!user.landlord,
          });
          window.userGuiding.identify(user.id, {
            email: user.email,
            name: `${user.first_name} ${user.last_name}`.trim(),
            created_at: user.created_at_timestamp_milliseconds,
            // Include landlord/company info if available
            ...(user.landlord && {
              company: {
                id: user.landlord.id,
                name:
                  user.landlord.company ||
                  `${user.landlord.first_name} ${user.landlord.last_name}`.trim(),
                created_at: user.landlord.created_at_timestamp_milliseconds,
              },
            }),
          });
        } else {
          console.warn('👣 UserGuiding script failed to load');
        }
      });
    } else {
      // eslint-disable-next-line no-console
      console.info('👣 UserGuiding enabled but no user available');
    }
  } catch (error) {
    console.error('👣 UserGuiding error getting user:', error);
  }

  // Provide helper methods
  return {
    provide: {
      userGuidingIdentify: (userId: string, attributes?: any) => {
        if (window.userGuiding) {
          window.userGuiding.identify(userId, attributes);
        }
      },
      userGuidingTrack: (event: string, properties?: any) => {
        if (window.userGuiding) {
          window.userGuiding.track(event, properties);
        }
      },
      userGuidingLaunchChecklist: (checklistId: string) => {
        if (window.userGuiding) {
          window.userGuiding.launchChecklist(checklistId);
        }
      },
    },
  };
});
