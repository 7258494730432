import type { PurchasePayload } from '@checkout/types';
import { useCheckoutStore } from '~/modules/checkout/stores';

export function createPurchasePayload<
  PurchasePayloadType extends PurchasePayload,
>(payloadData: { [key: string]: any }): PurchasePayloadType {
  const store = useCheckoutStore();

  const { cardHolderName, addressLine1, addressLine2, city, postcode } =
    store.billingDetails;

  const payload: PurchasePayloadType = {
    cardHolderName,
    address_1: addressLine1,
    address_2: addressLine2,
    city,
    postcode,
    payment_intent_id: store.form.paymentIntentId,
    ...payloadData,
  };
  return payload;
}
