import type { Pinia } from 'pinia';

import { useMainStore } from '~/stores/mainStore';
import { useEnquiriesStore } from '~/modules/enquiries/stores';

export default defineNuxtPlugin(({ $pinia }) => {
  return {
    provide: {
      store: useMainStore($pinia as Pinia),
      enquiriesStore: useEnquiriesStore($pinia as Pinia),
    },
  };
});
