import type { NotificationsResponse } from '@notifications/types/responses';
import type { GetNotificationsQuery } from '@notifications/types/requests';

export default {
  get(query: GetNotificationsQuery = {}) {
    return useApiFetch<NotificationsResponse>(
      useZiggy('agents.notifications.index'),
      {
        method: 'GET',
        query,
      }
    );
  },

  update(id = '', body = {}) {
    return useApiFetch(useZiggy('agents.notifications.update', id), {
      method: 'PUT',
      body,
    });
  },

  markAllAsRead() {
    return useApiFetch(useZiggy('agents.notifications.mark-all-as-read'), {
      method: 'PUT',
    });
  },
};
