<script setup lang="ts">
import { useMainStore } from '~/stores/mainStore';
const mainStore = useMainStore();

const shouldShowDiscountCodeComponent = computed(() => {
  return mainStore.siteSettings.allowDiscountCodeEntry;
});
</script>

<template>
  <div>
    <BillingDetails />
    <hr class="my-8" />
    <div v-if="shouldShowDiscountCodeComponent">
      <DiscountCode />
      <hr class="my-8" />
    </div>
    <CardDetails />
  </div>
</template>
