import FeaturePropertyStep from '../components/CheckoutSteps/FeaturePropertyStep/FeaturePropertyStep.vue';
import { useCheckoutStore } from '../stores/index';
import checkoutApi from './checkoutApi';
import type {
  CheckoutSchema,
  FeatureOrderData,
  OrderItem,
  PurchaseFeaturesPayload,
} from '@checkout/types';
import { usePropertiesStore } from '~/modules/properties/stores';
import PropertyData = Domain.Properties.DataObjects.PropertyData;

type InitData = number[] | Set<number>;

const featureCheckoutSchema: CheckoutSchema<InitData> = {
  title: 'Feature properties',
  successMessage: 'Congratulations. Your properties are featured!',
  component: FeaturePropertyStep,
  init: function (ids?: InitData): void {
    if (!ids) throw new Error('Invalid init feature checkout data provided.');
    if (Array.isArray(ids)) ids = new Set(ids);

    useCheckoutStore().propertyIds = ids;
  },
  submit: async function (): Promise<void> {
    const checkoutStore = useCheckoutStore();
    const toaster = useToaster();

    const properties = checkoutStore.items.map(
      (item: OrderItem<FeatureOrderData>) => {
        const { id, featureDuration } = item.data as FeatureOrderData;
        return {
          id,
          feature_duration: featureDuration,
        };
      },
    );

    const discountCode: string | null = checkoutStore.discountCode;

    const payload: PurchaseFeaturesPayload =
      createPurchasePayload<PurchaseFeaturesPayload>({
        discount_code: discountCode,
        properties,
      });

    const { data, error } = await checkoutApi.feature.purchase(payload);

    if (data.value?.status === 'success') {
      const featured = data.value?.properties as PropertyData[];

      featured.forEach((property: PropertyData) => {
        const propertiesStore = usePropertiesStore();

        const propertyIndex = propertiesStore.getPropertyIndex(property.id);
        if (propertyIndex === -1) return;

        // Replace the persisted property result data
        propertiesStore.tableResults[propertyIndex] = property;
      });

      await checkoutStore.complete();
    }

    if (error.value?.data?.message) {
      toaster.add('danger', error.value.data.message, 3000);
    }

    if (error.value?.data.status === 'failed') {
      checkoutStore.loading = false;
      throw new Error(error.value.data.message);
    }
  },
};

export default featureCheckoutSchema;
