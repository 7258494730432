import type { FetchOptions, FetchRequest } from 'ofetch';
import { ofetch } from 'ofetch';
import { defineNuxtPlugin, useCookie } from '#app';
import type {
  File,
  Options,
  PresignedResponse,
  PresignedResponseHeaders,
} from '../../types';

export default defineNuxtPlugin((nuxtApp) => {
  const vaporConfig = nuxtApp.$config.public.vapor;
  const authConfig = nuxtApp.$config.public.nuxtSanctumAuth;

  const apiFetch = (endpoint: FetchRequest, options?: FetchOptions) => {
    const fetch = ofetch.create({
      baseURL: vaporConfig.apiBaseUrl,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        [authConfig.csrf.headerKey]: useCookie(authConfig.csrf.cookieKey).value,
      } as HeadersInit,
    });

    return fetch(endpoint, options);
  };

  function createPresignedRequest(
    file: File,
    options: Options,
  ): Promise<PresignedResponse> {
    const url = options.signedStorageUrl ?? vaporConfig.signedStorageUrl;

    return apiFetch(url, {
      method: 'POST',
      body: {
        bucket: options.bucket || vaporConfig.awsBucketPrivate || '',
        content_type: options.contentType || file.type,
        expires: options.expires || '',
        visibility: options.visibility || '',
        ...options.data,
      },
      baseURL: options.baseURL || vaporConfig.apiBaseUrl,
      ...options.options,
    });
  }

  async function uploadVaporFile(file: File, options: Options) {
    let response = null;
    try {
      response = await createPresignedRequest(file, options);
    } catch (error) {
      console.error('Error:', error);
    }

    if (!response) {
      return;
    }

    const headers: PresignedResponseHeaders = response.headers;

    if ('Host' in headers) {
      delete headers.Host;
    }

    if (typeof options.progress === 'undefined') {
      options.progress = () => {};
    }

    const cancelToken: string = options.cancelToken || '';

    try {
      await $fetch(response.url, {
        method: 'PUT',
        body: file,
        headers,
        cancelToken,
      });
    } catch (error) {
      return {
        value: error,
      };
    }

    response.extension = file.name?.split('.').pop();

    return response;
  }

  return {
    provide: {
      uploadVaporFile,
      createPresignedRequest,
      vaporApiFetch: apiFetch,
    },
  };
});
