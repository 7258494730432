<script setup lang="ts">
import type { NuxtError } from '#app';

const props = defineProps<{ error: NuxtError }>();

const handleError = () => clearError({ redirect: '/' });

const statusMessage = computed(() => {
  switch (props.error.statusCode) {
    case 404:
      return "We can't find the page you were looking for";

    default:
      return props.error.statusMessage;
  }
});
</script>

<template>
  <NuxtLayout name="error">
    <div>
      <h2 class="text-center text-2xl font-semibold text-gray-900">
        Error {{ error.statusCode }}
      </h2>
      <p class="mb-2 max-h-[6.6rem] overflow-y-auto text-center text-base">
        {{ statusMessage }}
      </p>
      <div class="mx-auto max-w-[24rem]">
        <UhButton block @click="handleError">Back to dashboard</UhButton>
      </div>
    </div>
  </NuxtLayout>
</template>
