import type { Config, RouteParams } from 'ziggy-js';
import route from 'ziggy-js';
import { Ziggy } from '~/routes';

export default defineNuxtPlugin((nuxtApp) => {
  const ziggyRouter = (
    name: string,
    routeParam?: RouteParams<string> | undefined,
    absolute?: boolean | undefined
  ): string => {
    return route(name, routeParam, absolute, Ziggy as Config);
  };

  nuxtApp.provide('ziggy', ziggyRouter);
});
