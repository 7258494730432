<script setup lang="ts">
import type { UhModalProps } from '~/stores/modaler/types';
import { useCheckoutStore } from '~/modules/checkout/stores';

const { $closeModal } = useNuxtApp();
const checkoutStore = useCheckoutStore();

const props = defineProps<UhModalProps>();

function close(): void {
  $closeModal(props.modalId as number);
}

async function cancelCheckout(): Promise<void> {
  await checkoutStore.exit();
  close();
}
</script>

<template>
  <UhModal :open="true" @close="close">
    <template #header>
      <span class="font-semibold text-gray-900 dark:text-gray-100"
        >Cancel Checkout</span
      >
    </template>
    <template #body>
      <div>
        <p class="font-semibold text-gray-700 dark:text-gray-200">
          Are you sure you want to remove this property and cancel this
          checkout?
        </p>
        <p class="text-gray-600 dark:text-gray-100">
          Clicking yes will return you to your properties page.
        </p>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full items-end justify-end space-x-4">
        <UhButton color="light" @click="close">
          No, continue checkout
        </UhButton>
        <UhButton color="red" @click="cancelCheckout"> Yes, cancel </UhButton>
      </div>
    </template>
  </UhModal>
</template>
