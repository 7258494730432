import type { Component } from 'vue';

export enum CheckoutType {
  BOOST = 'boost',
  FEATURE = 'feature',
}

export interface CheckoutSchema<InitData = any> {
  title: string;
  successMessage: string;
  component: Component;
  init: (data?: InitData) => void;
  submit: (...args: any[]) => Promise<void>;
  valid?: () => boolean;
}

export type Checkouts = {
  [key in CheckoutType]: CheckoutSchema<any>;
};

export type OrderItem<T> = {
  name: string;
  amount: number;
  data?: T;
};

export type BillingDetails = {
  cardHolderName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postcode: string;
  email: string;
};

export type DiscountCount = {
  id: number;
  code: string;
  value: string;
  status: number;
  promoText: string;
  promoted: number;
};

export type PriceMap = Map<CheckoutType, any>;

export type FeaturePrice = {
  id: string;
  duration: number;
  price: number;
  priceTotal: number;
  priceVat: number;
};

export interface FeaturePricesResponse {
  data: FeaturePrice[];
}

export type FeatureOrderData = {
  id: number;
  priceId: string;
  featureDuration: number;
};

export type PurchasePayload = {
  cardHolderName: string;
  address_1: string;
  address_2?: string;
  city: string;
  postcode: string;
  payment_intent_id: string;
};

export type PurchaseFeaturesPayload = PurchasePayload & {
  discount_code?: string;
  properties: { id: number; feature_duration: number }[];
};

export type PurchaseBoostsPayload = PurchasePayload & {
  discount_code?: string;
  quantity: number;
};

export type PaymentIntentPayload = {
  amount: number;
  description: string;
};

export type BoostOrderData = {
  id: number;
  price: number;
  quantity: number;
  selected?: boolean;
  mostPopular?: boolean;
};
