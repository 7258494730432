<script setup lang="ts">
import type { ConfirmActionModalData } from '~/types/enquiries/modals';
import UhModal from '~/components/modals/UhModal.vue';
import UhButton from '~/components/general/uhButton/UhButton.vue';
import type { UhModalProps } from '~/stores/modaler/types';

const { $closeModal } = useNuxtApp();

const props = defineProps<UhModalProps<ConfirmActionModalData>>();

const busy = ref<boolean>(false);

async function performAction(): Promise<void> {
  busy.value = true;

  await props.data?.callback();

  close();
  busy.value = false;
}

function close(): void {
  $closeModal(props.modalId as number);
}

onMounted(() => {
  busy.value = false;
});
</script>

<template>
  <UhModal :open="true" :size="size" @close="close">
    <template #header>
      <span class="font-semibold text-gray-900 dark:text-gray-100">{{
        props.title ?? 'Confirm action'
      }}</span>
    </template>
    <template #body>
      <div class="text-gray-700 dark:text-gray-200">
        <p>
          <slot> {{ message ?? 'Are you sure?' }} </slot>
        </p>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full flex-col gap-3 sm:flex-row sm:justify-between">
        <div class="sm:order-0 order-1 w-full sm:w-auto">
          <UhButton color="light" :block="true" @click="close">Cancel</UhButton>
        </div>
        <div class="order-0 w-full sm:order-1 sm:w-auto">
          <UhButton :disabled="busy" :block="true" @click="performAction">
            {{ props.confirmLabel ?? 'Confirm' }}
          </UhButton>
        </div>
      </div>
    </template>
  </UhModal>
</template>
