import type { LocationQuery } from 'vue-router';
import type { MaybeRefOrGetter } from 'vue';
import { toRef } from '@vueuse/core';
import * as qs from 'qs';
import type { QueryParams } from './../../types/index';

export function useQueryBuilder() {
  function parse(query: LocationQuery) {
    const parsed = { ...qs.parse(query) };
    if ('page' in parsed && parsed.page !== undefined) {
      parsed.page = Number(parsed.page);
    }

    if ('perPage' in parsed && parsed.perPage !== undefined) {
      parsed.perPage = Number(parsed.perPage);
    }

    if ('sort' in parsed && typeof parsed.sort === 'string') {
      const sorts = parsed.sort
        .split(',')
        .map((sort) => {
          const name = sort.replace('-', '');
          const value = sort.charAt(0) !== '-';
          return { [name]: value };
        })
        .reduce((obj, sort) => Object.assign(obj, sort), {});

      parsed.sort = sorts;
    }

    return parsed;
  }

  function build(query: MaybeRefOrGetter<QueryParams>) {
    const build = {};

    const _query = toRef(query);

    Object.entries(_query.value).forEach(([queryKey, queryValue]) => {
      if (queryKey === 'sort' && typeof queryValue === 'object') {
        const sorts = Object.entries(queryValue)
          .filter(([, value]) => value !== null)
          .map(([key, value]) => {
            const desc = value === false ? '-' : '';
            return `${desc}${key}`;
          })
          .join(',');

        Object.assign(build, { sort: sorts });

        return;
      }

      if (queryKey === 'filter') {
        Object.entries(queryValue)
          .filter(([, value]) => value !== null && value !== '')
          .forEach(([key, value]) => {
            Object.assign(build, { [`filter[${key}]`]: value });
          });
        return;
      }

      if (queryValue !== null && queryValue !== undefined) {
        Object.assign(build, { [queryKey]: queryValue });
      }
    });

    return build;
  }

  return {
    parse,
    build,
  };
}
