<script setup lang="ts">
import UhToastProvider from '~/components/notifications/uhToasts/uhToastProvider/UhToastProvider.vue';

useHead({
  bodyAttrs: {
    class: 'bg-white dark:bg-gray-900',
  },
});

/* const auth = useAuth();
useGrowthBook({
  loggedIn: !!auth.user?.id,
  id: auth.user?.id,
}).then((gb) => {
  if (gb.isOn('test-feature')) {
    // eslint-disable-next-line no-console
    console.log('Test feature is enabled!');
    // eslint-disable-next-line no-console
    console.log(
      gb.getFeatureValue('test-feature', 'failed to get value from growthbook')
    );
  }

  // or

  // if (gb.fnMap('isOn', 'test-feature')) {
  //   console.log('Test feature is enabled!');
  //   console.log(
  //     gb.fnMap(
  //       'getFeatureValue',
  //       'test-feature',
  //       'failed to get value from growthbook',
  //     ),
  //   );
  // }
}); */
</script>

<template>
  <UhToastProvider transition="slide-left" position="top-right">
    <LayoutProvider />
  </UhToastProvider>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.2s;
}

.layout-enter-from,
.layout-leave-to {
  /* PC - Disabled gray transition effect */
  /* filter: grayscale(1); */
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.5s ease;
}
.slide-left-enter-from,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.5s ease;
}
.slide-right-enter-from,
.slide-right-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.slide-top-enter-active,
.slide-top-leave-active {
  transition: all 0.5s ease;
}
.slide-top-enter-from,
.slide-top-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-bottom-enter-active,
.slide-bottom-leave-active {
  transition: all 0.5s ease;
}
.slide-bottom-enter-from,
.slide-bottom-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
