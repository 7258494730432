import { defineStore } from 'pinia';
import type { UhModal, UhModalList } from '~/stores/modaler/types';

const MODAL_LIMIT = 1;

export const useModalerStore = defineStore('modaler', function () {
  const modals = ref<UhModalList>([]);

  function findModalIndex(id: number): number {
    return modals.value.findIndex((modal: UhModal): boolean => modal.id === id);
  }

  const hasOpenModal = computed<boolean>(() => modals.value.length > 0);

  function showModal<UhModalComponent>(
    modal: Partial<UhModal<UhModalComponent>>,
  ): number {
    if (modals.value.length > MODAL_LIMIT) {
      modals.value.shift();
    }

    const id: number = Date.now();
    modal.id = id;
    modal.time = id;

    modal.props = modal.props ?? {};
    modal.props.modalId = id;

    if (modal.duration) {
      modal.timer = window.setTimeout(() => {
        removeModal(id);
      }, modal.duration);
    }

    modals.value.push(modal as UhModal<UhModalComponent>);
    return id;
  }

  function removeModal(id: number): boolean {
    const modalIndex: number = findModalIndex(id);
    if (modalIndex < 0) {
      return false;
    }

    modals.value.splice(modalIndex, 1);
    return true;
  }

  return {
    modals,
    hasOpenModal,
    showModal,
    removeModal,
  };
});
