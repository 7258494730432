<script setup lang="ts">
import { ref, type Ref } from 'vue';
import { useCheckoutStore } from '~/modules/checkout/stores';

const toaster = useToaster();
const { $sentryCaptureException } = useNuxtApp();

const checkoutStore = useCheckoutStore();

const discountCode: Ref<string | null> = ref(null);
const checking: Ref<boolean> = ref(false);

const clearDiscountCode = () => {
  discountCode.value = null;

  checkoutStore.clearDiscount();
};

const checkDiscountCode = async () => {
  checking.value = true;

  await useApiFetch(useZiggy('agents.checkout.discount'), {
    method: 'POST',
    watch: false,
    body: {
      discount_code: discountCode.value,
    },
    onResponse({ response: { _data } }): Promise<void> | void {
      // Was the code not found
      if (_data.error) {
        toaster.add('danger', _data.error);
        clearDiscountCode();
      }

      // Was the code found
      if (_data.success) {
        checkoutStore.applyDiscount(_data);
        toaster.add('success', 'Discount code found and applied.');
      }
    },
    onRequestError({ error }) {
      $sentryCaptureException(error);

      toaster.add(
        'danger',
        'Sorry there was a problem checking your discount code, please try again.'
      );
    },
  });

  checking.value = false;
};
</script>

<template>
  <div>
    <h2 class="mb-4 text-base font-semibold">Discount Code</h2>

    <div class="flex flex-col flex-wrap gap-4 md:flex-row">
      <UhInput
        v-model="discountCode"
        :required="false"
        name="discountCode"
        label=""
        place-holder=""
      >
        <template #helper>
          If you have a valid discount code, please enter it here
        </template>
        <template v-if="discountCode" #suffix>
          <button
            type="button"
            class="relative -right-1 top-0.5 px-1.5"
            @click="clearDiscountCode"
          >
            <FontAwesomeIcon
              :icon="['fa', 'times']"
              class="h-3 w-3 text-gray-600"
            />
          </button>
        </template>
      </UhInput>
      <UhButton
        type="button"
        :disabled="!discountCode || checking"
        height="input"
        @click="checkDiscountCode"
      >
        Apply discount
      </UhButton>
    </div>
  </div>
</template>
