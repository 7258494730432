import type {
  BulkUpdateStatusPayload,
  EnquiriesQueryPayload,
} from '@enquiries/types/requests';
import type {
  EnquiriesResponse,
  EnquiriesWizardAvailableResponse,
} from '@enquiries/types/responses';

export default {
  get(query: EnquiriesQueryPayload) {
    return useApiFetch<EnquiriesResponse>(useZiggy('agents.enquiries.index'), {
      method: 'GET',
      query,
    });
  },
  getWizardAvailable() {
    return useApiFetch<EnquiriesWizardAvailableResponse>(
      useZiggy('agents.enquiries.wizard.isAvailable'),
      {
        method: 'GET',
      }
    );
  },

  actions: {
    status(body: BulkUpdateStatusPayload) {
      return useApiFetch(useZiggy('agents.enquiries.actions.status'), {
        method: 'PATCH',
        body,
      });
    },
  },
};
