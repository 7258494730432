import { storeToRefs } from 'pinia';
import { useMainStore } from '~/stores/mainStore';

export default defineNuxtPlugin((nuxtApp) => {
  const store = useMainStore();
  const { page } = storeToRefs(store);

  nuxtApp.hook('page:finish', () => {
    if (page && page.value) {
      page.value.scrollTo(0, 0);
    }
  });
});
