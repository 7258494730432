export default {
  auth: () => import("/home/runner/work/atlantic/atlantic/agents/layouts/auth.vue").then(m => m.default || m),
  blank: () => import("/home/runner/work/atlantic/atlantic/agents/layouts/blank.vue").then(m => m.default || m),
  checkout: () => import("/home/runner/work/atlantic/atlantic/agents/layouts/checkout.vue").then(m => m.default || m),
  default: () => import("/home/runner/work/atlantic/atlantic/agents/layouts/default.vue").then(m => m.default || m),
  error: () => import("/home/runner/work/atlantic/atlantic/agents/layouts/error.vue").then(m => m.default || m),
  framed: () => import("/home/runner/work/atlantic/atlantic/agents/layouts/framed.vue").then(m => m.default || m),
  import: () => import("/home/runner/work/atlantic/atlantic/agents/layouts/import.vue").then(m => m.default || m),
  preview: () => import("/home/runner/work/atlantic/atlantic/agents/layouts/preview.vue").then(m => m.default || m),
  settings: () => import("/home/runner/work/atlantic/atlantic/agents/layouts/settings.vue").then(m => m.default || m)
}