import type { PropertiesQuery } from '@properties/types';

export default {
  get(query: PropertiesQuery) {
    return useApiFetch(useZiggy('agents.properties.index'), {
      method: 'GET',
      query,
    });
  },
  getBoostPricing() {
    return useApiFetch<Domain.Properties.DataObjects.BoostPriceData[]>(
      useZiggy('agents.properties.boost.pricing'),
      {
        method: 'GET',
        watch: false,
      },
    );
  },
  getPropertyWillGoLive(id: number) {
    return useApiFetch<Domain.Properties.DataObjects.PropertyStatusActionData>(
      useZiggy('agents.properties.will-go-live', id),
      {
        method: 'GET',
        watch: false,
      },
    );
  },
  getPropertiesWillGoLive(ids: number[]) {
    const query: { [key: string]: number } = {};

    ids.forEach((id: number, index: number) => {
      Object.assign(query, { [`ids[${index}]`]: id });
    });

    return useApiFetch<Domain.Properties.DataObjects.PropertyBulkActionData>(
      useZiggy('agents.properties.actions.will-go-live'),
      {
        method: 'GET',
        watch: false,
        query,
      },
    );
  },
  postSetToLive(propertyId: number) {
    return useApiFetch<{
      message: string;
      liveAt?: string;
    }>(useZiggy('agents.properties.make-live', propertyId), {
      method: 'POST',
    });
  },
  postBulkSetToLive(ids: number[]) {
    return useApiFetch<Domain.Properties.DataObjects.PropertyBulkActionData>(
      useZiggy('agents.properties.actions.make-live'),
      {
        method: 'POST',
        body: {
          ids,
        },
      },
    );
  },
};
