// https://pipinghot.dev/tutorial/use-sentry-with-nuxt-3/
import {
  addBreadcrumb,
  BrowserTracing,
  captureException,
  init,
  setContext,
  setTag,
  setUser,
  vueRouterInstrumentation,
} from '@sentry/vue';
import { Replay } from '@sentry/browser';
import { HttpClient } from '@sentry/integrations';
import type { RuntimeConfig } from 'nuxt/schema';
import { defineNuxtPlugin } from '#app';
import * as pkg from '~/package.json';

export default defineNuxtPlugin(async (nuxtApp) => {
  const { vueApp, $router, $sanctumAuth } = nuxtApp;

  // @ts-ignore
  const user = await $sanctumAuth.getUser();
  const release = `${pkg.name}@${pkg.version}`;
  const config: RuntimeConfig = nuxtApp.$config;
  const dsn = config.public.sentry.dsn;
  const environment = config.public.sentry.environment;

  init({
    app: [vueApp],
    dsn,
    release,
    environment,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: vueRouterInstrumentation($router),
        tracePropagationTargets: [
          'agents.unihomes.test',
          'agents.unihomes.test:3000',
          /^https:\/\/agents.unihomes.co.uk/,
          /^https:\/\/staging.agents.unihomes.co.uk/,
          /^\//,
        ],
      }),
      new Replay({
        networkDetailAllowUrls: [
          window.location.origin,
          /^https:\/\/www.unihomes.co.uk/,
          /^https:\/\/staging.unihomes.co.uk/,
        ],
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        slowClickIgnoreSelectors: [
          // https://github.com/getsentry/sentry-javascript/issues/9087#issuecomment-1730868537
          // https://docs.sentry.io/platforms/javascript/guides/nextjs/session-replay/troubleshooting/#how-does-rage-click-detection-work
          '[aria-label*="browse" i]',
          '[aria-label*="download" i]',
          '[aria-label*="export" i]',
          '[title*="browse" i]',
          '[title*="download" i]',
          '[title*="export" i]',
        ],
      }),
      new HttpClient(),
    ],
    sampleRate: 1,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    logErrors: environment !== 'production',
    attachProps: true,
    attachStacktrace: true,
    tracingOptions: {
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    },
    initialScope: {
      tags: { buildDate: config.public.buildDate },
      user: { email: user?.email ?? null },
    },
    sendDefaultPii: true,
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          { event, hint },
        );
      }
      // Continue sending to Sentry
      return event;
    },
  });

  if (user) {
    // eslint-disable-next-line no-console
    console.log('🪵 Sentry - Setting user as: ', user.email);
  }

  return {
    provide: {
      sentrySetContext: setContext,
      sentrySetUser: setUser,
      sentrySetTag: setTag,
      sentryAddBreadcrumb: addBreadcrumb,
      sentryCaptureException: captureException,
    },
  };
});
