<script setup lang="ts" generic="T">
import { ref, computed, useSlots } from 'vue';
import type { SelectOptionsValue, SelectOption } from './types.d.ts';

const slots = useSlots();

interface SelectMenuProps<T> {
  id: string;
  name: string;
  label?: string;
  modelValue: SelectOptionsValue<T>;
  disabled?: boolean;
  placeholder?: string;
  options: SelectOption<T>[];
  align?: 'left' | 'right' | 'none';
  invalid?: boolean;
  errorMessage?: string;
  buttonClass?: string;
  buttonWrapperClass?: string;
  labelClass?: string;
  sideLabelClass?: string;
  subLabelClass?: string;
  quickFindOption?: boolean;
  outerClasses?: string;
  placeholderTriggers?: any[];
  openIconClass?: string;
  closeIconClass?: string;
  wrapperIconClass?: string;
  dataTestid?: string;
  required?: boolean;
  dropdownZIndex?: string;
}

const props = withDefaults(defineProps<SelectMenuProps<T>>(), {
  label: '',
  disabled: false,
  placeholder: undefined,
  align: 'none',
  invalid: false,
  errorMessage: '',
  buttonClass: '',
  buttonWrapperClass: '',
  labelClass: '',
  sideLabelClass: '',
  subLabelClass: '',
  quickFindOption: true,
  outerClasses: '',
  placeholderTriggers: () => [null],
  openIconClass: '',
  closeIconClass: '',
  wrapperIconClass: '',
  dataTestid: '',
  required: false,
  dropdownZIndex: 'z-10'
});

const emit = defineEmits(['update:modelValue']);

const open = ref<boolean>(false);
const menuRefs = ref<HTMLLIElement[]>([]);
const focusedOption = ref(0);
const selectMenuRef = ref<HTMLDivElement | null>(null);

onClickOutside(selectMenuRef, closeMenu);

const selectedName = computed<string | undefined>(() => {
  const option: SelectOption<T> | undefined = getSelectedOption(
    props.modelValue,
  );

  if (!option) return undefined;

  return option.selectedName ?? option.name;
});

function getSelectedOption(
  value: SelectOptionsValue<T>,
): SelectOption<T> | undefined {
  return props.options?.find((o: SelectOption<T>) => o.value === value);
}

const showPlaceholder = computed<boolean>(() => {
  return (props.placeholderTriggers?.includes(props.modelValue) &&
    props.placeholder) as boolean;
});

function openMenu() {
  open.value = true;
}
function closeMenu() {
  open.value = false;
}
function toggleMenu() {
  open.value = !open.value;
}
function focusNextOption(event: Event): void {
  event.preventDefault();
  openMenu();
  focusedOption.value =
    focusedOption.value === props.options.length - 1
      ? 0
      : focusedOption.value + 1;
  menuRefs.value[focusedOption.value]?.scrollIntoView({
    block: 'nearest',
  });
}
function focusPrevOption(event: Event): void {
  event.preventDefault();
  openMenu();
  focusedOption.value =
    focusedOption.value === 0
      ? props.options.length - 1
      : focusedOption.value - 1;
  menuRefs.value[focusedOption.value]?.scrollIntoView({
    block: 'nearest',
  });
}
function selectOption(option: KeyboardEvent | SelectOption<T>): void {
  if (option instanceof KeyboardEvent) {
    option = props.options[focusedOption.value];
  }
  closeMenu();
  emit('update:modelValue', option.value);
}

function optionElementId(index: number): string {
  return `listbox-option-${index}`;
}

function handleKeyPressEvent(event: KeyboardEvent): void {
  if (!props.quickFindOption) return;

  const key: string = event.key;
  const regex: RegExp = /^[a-zA-Z]*$/;

  if (!regex.test(key)) {
    // Not a letter
    return;
  }

  const index = props.options.findIndex((option: SelectOption<T>) => {
    return option.name.toLowerCase().startsWith(key.toLowerCase());
  });

  if (index === -1) {
    // No option with name starting with key pressed found
    return;
  }

  document.getElementById(optionElementId(index))?.scrollIntoView();
}
</script>

<template>
  <div
    ref="selectMenuRef"
    :class="props.outerClasses"
    @keypress="handleKeyPressEvent"
  >
    <label
      v-if="props.label && !slots.sideLabel"
      :id="props.id"
      class="mb-2 block text-sm font-medium text-gray-900"
      :class="props.labelClass"
      @click="toggleMenu"
    >
      <span>{{ label }}</span>
      <span
        v-if="props.required"
        class="ml-1 text-red-500"
      >*
      </span>
    </label>
    <div v-else-if="slots.sideLabel" class="flex justify-between">
      <label
        v-if="props.label"
        :id="props.id"
        class="mb-2 block text-sm font-medium text-gray-900"
        :class="props.labelClass"
        @click="toggleMenu"
      >
        <span>{{ label }}</span>
      </label>
      <span class="mt text-sm text-gray-900" :class="props.sideLabelClass">
        <slot name="sideLabel"></slot>
      </span>
    </div>
    <span
      v-if="slots.subLabel"
      class="mt text-sm text-gray-900"
      :class="props.subLabelClass"
    >
      <slot name="subLabel"></slot>
    </span>
    <div
      class="relative"
      :class="[
        props.align === 'left' ? 'float-left' : '',
        props.align === 'right' ? 'float-right' : '',
        props.buttonWrapperClass,
      ]"
    >
      <button
        type="button"
        :title="selectedName"
        class="relative flex min-h-[42px] w-full items-center rounded-lg border border-gray-300 bg-white py-2 pl-3 pr-10 text-sm text-gray-900 hover:border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:hover:border-gray-300"
        :class="[
          {
            'gap-2.5': slots.icon,
            'cursor-not-allowed': props.disabled,
            'border-1 border-red-600': props.invalid,
          },
          buttonClass,
        ]"
        aria-haspopup="listbox"
        aria-expanded="true"
        :aria-labelledby="id"
        :disabled="props.disabled"
        :data-testid="`button-${props.dataTestid}`"
        @click="toggleMenu"
        @keydown.enter.prevent="selectOption"
        @keydown.arrow-down="focusNextOption"
        @keydown.arrow-up="focusPrevOption"
        @keydown.escape="closeMenu"
      >
        <span v-if="slots.icon">
          <slot name="icon"></slot>
        </span>
        <span
          class="block truncate text-left text-sm"
          :class="{ 'text-gray-500 dark:text-gray-100': showPlaceholder }"
        >
          <template v-if="!showPlaceholder">
            {{ selectedName }}
          </template>

          <template v-if="showPlaceholder">
            {{ props.placeholder }}
          </template>
        </span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          :class="wrapperIconClass"
        >
          <FontAwesomeIcon
            v-if="open"
            class="text-gray-500"
            :icon="['fas', 'chevron-up']"
            :class="openIconClass"
          />
          <FontAwesomeIcon
            v-else
            class="text-gray-500"
            :icon="['fas', 'chevron-down']"
            :class="closeIconClass"
          />
        </span>
      </button>
      <Transition
        enter-from-class="opacity-0"
        enter-active-class="ease-in-out duration-100"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
        leave-active-class="ease-in-out duration-300"
        leave-to-class="opacity-0"
      >
        <ul
          v-if="open"
          class="absolute mb-0 ml-0 mt-1 max-h-60 w-full min-w-fit overflow-auto rounded-md bg-white py-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          :class="{
            'left-0': props.align === 'left',
            'right-0': props.align === 'right',
           [props.dropdownZIndex]: true
          }"
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-option-3"
          :data-testid="`dropdown-${props.dataTestid}`"
        >
          <li
            v-for="(option, index) in props.options"
            :id="optionElementId(index)"
            ref="menuRefs"
            :key="index"
            :tabindex="index === focusedOption ? 0 : -1"
            role="option"
            class="relative cursor-pointer select-none px-3 py-2.5 text-gray-700 hover:bg-blue-500 hover:text-white"
            :class="{
              'bg-blue-500 text-white': index === focusedOption,
            }"
            :data-testid="`option-${props.dataTestid}-${option.value}`"
            @mouseover="focusedOption = index"
            @click.prevent="selectOption(option)"
            @keydown.enter.prevent="selectOption"
            @keydown.arrow-down="focusNextOption"
            @keydown.arrow-up="focusPrevOption"
          >
            <span
              class="block truncate"
              :data-testid="`span-${props.dataTestid}`"
              :class="{
                'font-bold': option.value === props.modelValue,
                'font-normal': option.value !== props.modelValue,
              }"
            >
              {{ option.name }}
            </span>
          </li>
        </ul>
      </Transition>
    </div>
    <p
      v-if="slots.helper || props.invalid"
      class="mt-2 text-sm text-gray-500 dark:text-gray-400"
    >
      <span v-if="props.invalid" class="mt-2 text-red-600">
        {{ errorMessage }}
      </span>
      <slot name="helper"></slot>
    </p>
  </div>
</template>
