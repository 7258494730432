<script setup lang="ts">
import simplur from 'simplur';
import { useCheckoutStore } from '~/modules/checkout/stores';
import PropertyData = Domain.Properties.DataObjects.PropertyData;
import { usePropertiesStore } from '~/modules/properties/stores';
import {
  CheckoutType,
  type FeatureOrderData,
  type FeaturePrice,
  type OrderItem,
} from '~/modules/checkout/types';
import CancelCheckoutModal from '~/modules/checkout/components/CheckoutSteps/CancelCheckoutModal.vue';
import UhSelectTable from '~/components/general/uhTable/UhSelectTable.vue';
import UhSelectTableRow from '~/components/general/uhTable/UhSelectTableRow.vue';
import UhSelectTableHead from '~/components/general/uhTable/UhSelectTableHead.vue';
import type { SelectOption } from '~/components/forms/types';

const { $showModal } = useNuxtApp();
const { formatPrice } = useHelpers();

const checkoutStore = useCheckoutStore();
const propertiesStore = usePropertiesStore();

type FeatureList = { [id: number]: string | null };

type SelectedFeatures = { [propertyId: number]: string };

const watchSelectedFeatures = ref<boolean>(false);
const selectedFeatures = reactive<SelectedFeatures>({});

/** Potentially inefficient - Should add/remove individual item changes */
watch(selectedFeatures, (newSelectedFeatures: SelectedFeatures) => {
  if (!watchSelectedFeatures.value) return;

  const itemList: OrderItem<FeatureOrderData>[] = [];

  for (const [propertyId, productId] of Object.entries(newSelectedFeatures)) {
    const product = getFeaturePrice(productId);

    if (product) {
      itemList.push({
        name: simplur`${product?.duration} day[|s] feature`,
        amount: product?.price * 100,
        data: {
          id: parseInt(propertyId),
          priceId: product.id,
          featureDuration: product?.duration,
        },
      });
    }
  }
  checkoutStore.items = itemList;
});

onBeforeMount(() => {
  const list: FeatureList = {};

  // Refill the feature list from the items already put in the store
  for (const checkoutItem of checkoutStore.items) {
    const itemData = checkoutItem.data as FeatureOrderData;
    list[itemData.id] = itemData.priceId;
  }

  for (const propertyId of checkoutStore.propertyIds) {
    if (!list[propertyId]) list[propertyId] = null;
  }

  Object.assign(selectedFeatures, list);
});

onMounted(() => {
  watchSelectedFeatures.value = true;
});

function convertPriceToSelectOption(item: FeaturePrice): SelectOption<string> {
  const daysLabel: string = simplur`${item.duration} day[|s]`;

  return {
    value: item.id,
    name: `${daysLabel} (£${formatPrice(item.price)})`,
    selectedName: daysLabel,
  };
}

const priceOptions = computed<SelectOption<string>[]>(() => {
  const options = [];

  for (const featurePrice of checkoutStore.prices.get(CheckoutType.FEATURE)) {
    options.push(convertPriceToSelectOption(featurePrice));
  }

  return options;
});

function getFeaturePrice(productId: string): FeaturePrice | undefined {
  return (
    checkoutStore.prices.get(CheckoutType.FEATURE) as FeaturePrice[]
  ).find((price: FeaturePrice) => price.id === productId);
}

function getProperty(id: number): PropertyData {
  const data: PropertyData | null = propertiesStore.getProperty(id);
  if (!data)
    throw new Error(
      `Property with ID ${id} doesn't exist in the properties store.`
    );

  return data;
}

const properties = computed<PropertyData[]>(() => {
  const list = [];
  for (const id of checkoutStore.propertyIds) {
    list.push(getProperty(id));
  }
  return list;
});

function formatAddress(property: PropertyData): string {
  return `${property.shortAddress}, ${property.area?.name}, ${property.postcode}`;
}

function removeProperty(propertyId: number): boolean {
  if (checkoutStore.propertyIds.size === 1) {
    $showModal(CancelCheckoutModal, {
      closable: true,
      id: 'cancel-checkout-modal',
      size: 'md',
    });
    return false;
  }

  if (!checkoutStore.propertyIds.delete(propertyId)) {
    return false;
  }

  delete selectedFeatures[propertyId];

  return true;
}
</script>

<template>
  <UhSelectTable>
    <template #head>
      <UhSelectTableHead class="min-w-48">Property name</UhSelectTableHead>
      <UhSelectTableHead class="min-w-48">Feature duration</UhSelectTableHead>
      <UhSelectTableHead class="min-w-36">Price excl. VAT</UhSelectTableHead>
      <UhSelectTableHead />
    </template>

    <template #body>
      <UhSelectTableRow v-for="property in properties" :key="property.id">
        <td class="p-4 text-gray-900 dark:text-white">
          <span class="whitespace-normal text-sm xs:text-base">
            {{ formatAddress(property) }}
          </span>
        </td>
        <td class="p-4">
          <UhSelect
            :id="`feature-prices-${property.id}`"
            v-model="selectedFeatures[property.id]"
            :name="`feature-prices-${property.id}`"
            :options="priceOptions"
            placeholder="Select duration"
          ></UhSelect>
        </td>
        <td class="p-4 text-center">
          <span>
            £{{ getFeaturePrice(selectedFeatures[property.id])?.price ?? 0 }}
          </span>
        </td>
        <td class="p-4">
          <!-- Remove button -->
          <UhButton
            size="1:1"
            :block="false"
            color="transparent"
            class="hover:bg-gray-50"
            @click="removeProperty(property.id)"
          >
            <FontAwesomeIcon class="h-4 w-4" :icon="['fa', 'xmark']" />
          </UhButton>
        </td>
      </UhSelectTableRow>
    </template>
  </UhSelectTable>
</template>
