import { defineStore } from 'pinia';
import { computed, type ComputedRef, ref, type Ref } from 'vue';
import BillingStep from '../components/CheckoutSteps/BillingStep/BillingStep.vue';
import checkoutApi from '../services/checkoutApi';
import featureCheckoutSchema from '../services/featureCheckout';
import boostCheckoutSchema from '../services/boostCheckout';
import {
  type BillingDetails,
  type Checkouts,
  CheckoutType,
  type OrderItem,
  type PriceMap,
} from '@checkout/types';
import {
  CONTINUE_TO_BILLING_LABEL,
  EXIT_CHECKOUT_LABEL,
  PAYMENT_LABEL,
  PREVIOUS_STEP_LABEL,
} from '~/modules/checkout/constants';

const TAX_RATE = 0.2;

const emptyBillingDetails = {
  cardHolderName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  postcode: '',
  email: '',
};

const emptyPaymentForm = {
  paymentIntentId: '',
  card: false,
};

export const useCheckoutStore = defineStore('checkout', () => {
  const checkouts: Checkouts = {
    [CheckoutType.BOOST]: boostCheckoutSchema,
    [CheckoutType.FEATURE]: featureCheckoutSchema,
  };

  const form = reactive(emptyPaymentForm);

  const loading = ref(false);
  const submit = ref(1);
  const activeStep = ref<number>(0);
  const type = ref<CheckoutType>();
  const items = ref<OrderItem<any>[]>([]);
  const propertyIds = ref<Set<number>>(new Set());
  const returningRoute = ref<string>('/');
  const billingDetails = ref<BillingDetails>(emptyBillingDetails);
  const prices = ref<PriceMap>(new Map());

  const discountCode: Ref<string | null> = ref(null);
  const discountCodeId: Ref<number | null> = ref(null);
  const discountValue: Ref<number | null> = ref(null);

  const checkoutTitle = computed<string>(() => {
    return type.value ? checkouts[type.value].title : '';
  });

  const checkoutComponent = computed<Component | null>(() => {
    if (!type.value) return null;
    return activeStep.value > 0 ? BillingStep : checkouts[type.value].component;
  });

  const backButtonLabel = computed<string>(() =>
    activeStep.value === 0 ? EXIT_CHECKOUT_LABEL : PREVIOUS_STEP_LABEL
  );
  const continueButtonLabel = computed<string>(() =>
    activeStep.value === 0 ? CONTINUE_TO_BILLING_LABEL : PAYMENT_LABEL
  );

  const subtotal = computed<number>(() => {
    let subtotal = 0;
    items.value.map((item: OrderItem<any>) => (subtotal += item.amount));
    return subtotal;
  });

  /**
   * What we should multiply the subtotal with to get the discounted total
   */
  const discountMultiplier: ComputedRef<number> = computed<number>(
    (): number => {
      return !discountValue.value ? 1 : 1 - discountValue.value / 100;
    }
  );

  /**
   * The final value before tax
   */
  const discountedSubtotal: ComputedRef<number> = computed<number>(() => {
    return subtotal.value * discountMultiplier.value;
  });

  /**
   * The 'positive' difference between original cost and discount cost
   */
  const discountAmount: ComputedRef<number | null> = computed<number | null>(
    (): number | null => {
      return !discountValue.value
        ? null
        : subtotal.value - discountedSubtotal.value;
    }
  );

  const tax = computed<number>(() => {
    return discountedSubtotal.value * TAX_RATE;
  });

  const total = computed<number>(() => {
    return discountedSubtotal.value + tax.value;
  });

  const applyDiscount = (discountData: {
    code: string;
    id: number;
    value: number;
  }): void => {
    discountCode.value = discountData.code;
    discountCodeId.value = discountData.id;
    discountValue.value = discountData.value;
  };

  const clearDiscount = (): void => {
    discountCode.value = null;
    discountCodeId.value = null;
    discountValue.value = null;
  };

  function addItem<MetadataType = any>(item: OrderItem<MetadataType>): void {
    items.value.push(item);
  }

  function removeItem(index: number): void {
    items.value = items.value.splice(index, 1);
  }

  const hasOrderItems = computed<boolean>(() => items.value.length > 0);

  function nextStep(): void {
    if (activeStep.value === 1) {
      submit.value++;
      return;
    }

    activeStep.value = 1;
  }

  async function prevStep(): Promise<void> {
    if (activeStep.value === 0) {
      return await exit();
    }

    activeStep.value = 0;
  }

  async function start<InitData>(
    type: CheckoutType,
    initData?: InitData,
    routeOnComplete?: string
  ): Promise<void> {
    returningRoute.value = routeOnComplete ?? useRoute().fullPath;
    activeStep.value = 0;

    if (initData) {
      checkouts[type].init(initData);
    }

    await navigateTo(`/checkout?type=${type}`);
  }

  function reset(): void {
    loading.value = false;
    submit.value = 1;
    returningRoute.value = '/';
    items.value = [];
    prices.value = new Map();
    propertyIds.value = new Set();
    billingDetails.value = emptyBillingDetails;
    Object.assign(form, emptyPaymentForm);
  }

  function resetOrderItems(): void {
    items.value = [];
  }

  async function exit(resetCheckout: boolean = true): Promise<void> {
    await navigateTo(returningRoute.value);

    if (resetCheckout) {
      // Reset checkout state
      reset();
    }
    activeStep.value = 0;
  }

  async function complete(): Promise<void> {
    // Clear out any codes for next purchase
    clearDiscount();
    await navigateTo('/checkout/success');
  }

  async function createPaymentIntentClientSecret(): Promise<string | null> {
    const { data } = await checkoutApi.createPaymentIntent({
      amount: total.value,
      description: `${type.value}`,
    });

    if (data.value.status === 'error') {
      return null;
    }

    return data.value.client_secret;
  }

  return {
    form,
    // State
    discountAmount,
    discountCode,
    discountedSubtotal,
    loading,
    activeStep,
    backButtonLabel,
    billingDetails,
    continueButtonLabel,
    checkouts,
    checkoutComponent,
    checkoutTitle,
    items,
    hasOrderItems,
    propertyIds,
    returningRoute,
    subtotal,
    tax,
    total,
    type,
    submit,
    prices,
    // Methods
    applyDiscount,
    clearDiscount,
    start,
    complete,
    reset,
    exit,
    nextStep,
    prevStep,
    addItem,
    removeItem,
    resetOrderItems,
    createPaymentIntentClientSecret,
  };
});
