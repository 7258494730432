<script setup lang="ts">
import { cva } from 'class-variance-authority';
import type { BoostOrderData } from '~/modules/checkout/types';

const { formatPrice } = useHelpers();

const styles = cva('flex cursor-pointer items-center rounded-md p-4', {
  variants: {
    selected: {
      true: '!border-blue-500 !bg-blue-50',
    },
    mostPopular: {
      true: 'bg-blue-500 hover:bg-blue-600',
      false: 'border border-gray-200 bg-white hover:bg-gray-50',
    },
  },
});

const props = withDefaults(defineProps<BoostOrderData>(), {
  selected: false,
  mostPopular: false,
});

const iconColour = computed<string>(() =>
  props.mostPopular ? 'text-white' : 'text-yellow-500'
);

const textColour = computed<string>(() =>
  props.mostPopular ? 'text-white' : 'text-gray-900'
);
</script>

<template>
  <div
    class="relative flex items-center rounded-md p-4"
    :class="
      styles({
        selected,
        mostPopular,
      })
    "
  >
    <div
      v-if="mostPopular"
      class="absolute right-0 top-0 rounded-bl-md rounded-tr-md bg-orange-500 px-4 py-px text-xs text-white"
    >
      Most popular
    </div>
    <div class="my-2 inline-flex w-full justify-between">
      <div>
        <FontAwesomeIcon
          class="mr-2 h-5 w-5"
          :class="iconColour"
          :icon="['far', 'rocket']"
        />
        <span class="font-semibold" :class="textColour">
          {{ quantity.toLocaleString() }} Boosts
        </span>
      </div>
      <span class="font-semibold" :class="textColour">
        £{{ formatPrice(price) }}
      </span>
    </div>
  </div>
</template>
