import type { RouteLocationNormalized } from 'vue-router';
import { usePropertiesStore } from '@properties/stores';
import { CheckoutType } from '~/modules/checkout/types';
import { useCheckoutStore } from '~/modules/checkout/stores';

export default defineNuxtPlugin(() => {
  const propertiesStore = usePropertiesStore();
  const checkoutStore = useCheckoutStore();

  function cancelNavigation(fromPath?: string) {
    if (fromPath && !fromPath.startsWith('/checkout')) {
      return navigateTo(fromPath);
    }

    return abortNavigation();
  }

  addRouteMiddleware('property-action', () => {
    if (propertiesStore.selectedIds.size === 0) {
      return navigateTo('/properties');
    }
  });

  addRouteMiddleware(
    'checkout',
    (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      const { query } = to;

      if (!query.type) {
        return cancelNavigation(from.path);
      }

      const type = query.type as CheckoutType;

      if (!Object.values(CheckoutType).includes(type)) {
        return cancelNavigation(from.path);
      }

      checkoutStore.type = type;
    },
  );
});
