import type { AsyncData } from 'nuxt/app';
import type {
  PaymentIntentPayload,
  PurchaseBoostsPayload,
  PurchaseFeaturesPayload,
} from '../types';
import { useApiFetch } from '~/modules/support/fetch/runtime/composables/useApiFetch';

export default {
  getBillingDetails(): AsyncData<
    App.DataObjects.Payment.BillingDetailsData,
    any
  > {
    return useApiFetch(useZiggy('agents.checkout.billing-details'), {
      method: 'GET',
      watch: false,
    });
  },
  createPaymentIntent(body: PaymentIntentPayload): AsyncData<any, any> {
    return useApiFetch(useZiggy('agents.checkout.payment-intent'), {
      method: 'POST',
      body,
      watch: false,
    });
  },
  boost: {
    purchase(body: PurchaseBoostsPayload): AsyncData<any, any> {
      return useApiFetch(useZiggy('agents.checkout.boost'), {
        method: 'POST',
        body,
        watch: false,
      });
    },
  },
  feature: {
    purchase(body: PurchaseFeaturesPayload): AsyncData<any, any> {
      return useApiFetch(useZiggy('agents.checkout.feature'), {
        method: 'POST',
        body,
        watch: false,
      });
    },
  },
};
