<script setup lang="ts">
import type {
  Stripe,
  StripeCardElement,
  StripeElements,
} from '@stripe/stripe-js';
import { ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { mustBeTrue } from '~/composables/useValidators';
import { useCheckoutStore } from '~/modules/checkout/stores';

const { cdnAsset } = useHelpers();
const toaster = useToaster();

const ACCEPTED_CARDS = {
  Visa: cdnAsset('svg/checkout/card-visa.svg'),
  MasterCard: cdnAsset('svg/checkout/card-mastercard.svg'),
  Maestro: cdnAsset('svg/checkout/card-maestro.svg'),
  'American Express': cdnAsset('svg/checkout/card-amex.svg'),
  Discover: cdnAsset('svg/checkout/card-discover.svg'),
  CB: cdnAsset('svg/checkout/card-cb.svg'),
};

const loading = ref(false);
const paymentError = ref('');
const $externalResults = ref({});

const checkoutStore = useCheckoutStore();

const rules = {
  card: {
    mustBeTrue: helpers.withMessage('Card details are required', mustBeTrue),
  },
};

const v$ = useVuelidate(rules, checkoutStore.form, { $externalResults });

const stripe: Stripe = useClientStripe();

const cardRef = ref<HTMLDivElement | null>(null);
let card: StripeCardElement;

onMounted(() => {
  loading.value = true;

  const elements: StripeElements = stripe.elements();

  card = elements.create('card');

  card.on('change', (event) => {
    $externalResults.value = {};
    checkoutStore.form.card = !event.empty;
    v$.value.card.$touch();

    if (event.error) {
      $externalResults.value = { card: event.error.message };
    }
  });

  if (cardRef.value) {
    card.mount(cardRef.value);
    card.on('blur', () => v$.value.$touch());
  }

  loading.value = false;
});

watch(
  () => checkoutStore.submit,
  async () => await confirmCardPayment()
);

async function confirmCardPayment(): Promise<void> {
  if (!checkoutStore.type) {
    throw new Error('Checkout type cannot be null before submitting payment.');
  }

  checkoutStore.loading = true;

  const clientSecret = await checkoutStore.createPaymentIntentClientSecret();
  if (!clientSecret) {
    checkoutStore.loading = false;
    return;
  }

  const { paymentIntent, error } = await stripe.confirmCardPayment(
    clientSecret,
    {
      payment_method: {
        card,
        billing_details: {
          address: {
            city: checkoutStore.billingDetails.city,
            country: 'GB',
            line1: checkoutStore.billingDetails.addressLine1,
            line2: checkoutStore.billingDetails.addressLine2,
            postal_code: checkoutStore.billingDetails.postcode,
          },
          email: checkoutStore.billingDetails.email,
          name: checkoutStore.billingDetails.cardHolderName,
        },
      },
    }
  );

  if (error) {
    checkoutStore.loading = false;
    toaster.add('danger', error.message as string);
    return;
  }

  if (
    !paymentIntent ||
    typeof paymentIntent.id === 'undefined' ||
    paymentIntent.id === null
  ) {
    checkoutStore.loading = false;
    throw new Error(
      'Stripe payment intent cannot be null before submitting payment.'
    );
  }

  checkoutStore.form.paymentIntentId = paymentIntent.id;

  await checkoutStore.checkouts[checkoutStore.type].submit();
  checkoutStore.loading = false;
}
</script>

<template>
  <div>
    <div class="mb-4 flex items-center justify-between">
      <h2 class="text-base font-semibold">Card details</h2>

      <div class="grid grid-cols-6 gap-2">
        <img
          v-for="(asset, name) in ACCEPTED_CARDS"
          :key="name"
          :src="asset"
          :alt="name"
        />
      </div>
    </div>

    <div
      v-show="!loading"
      ref="cardRef"
      class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
    ></div>

    <p v-if="v$.card.$errors.length" class="mt-2 text-red-500">
      {{ v$.card.$errors.pop().$message }}
    </p>

    <p v-if="paymentError.length" class="mt-2 text-red-500">
      {{ paymentError }}
    </p>
  </div>
</template>
