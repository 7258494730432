<script setup lang="ts">
import { useModalerStore } from '~/stores/modaler/modaler';

const modalerStore = useModalerStore();
</script>

<template>
  <div
    v-if="modalerStore.hasOpenModal"
    class="fixed inset-0 z-10 !my-0 h-full w-full"
  >
    <div v-for="modal in modalerStore.modals" :key="modal.id">
      <Component :is="modal.component" v-bind="modal.props"></Component>
    </div>
  </div>
</template>
