import { defineStore } from 'pinia';
import { computed, reactive, ref, watch } from 'vue';
import dayjs from 'dayjs';
import { EnquiryPropertyStatus } from '../types';
import enquiriesApi from '@enquiries/services/enquiriesApi';
import type { BulkUpdateStatusPayload } from '@enquiries/types/requests';
import ConfirmEnquiryActionModal from '@enquiries/components/ConfirmEnquiryActionModal.vue';
import { TABLE_LOADING_DELAY } from '~/constants';
import type {
  EnquiriesFilters,
  EnquiriesQuery,
  EnquiryProperty,
  filterTypes,
} from '@/types/enquiries/types';
import { useHelpers } from '~/composables/useHelpers';
import type { PaginatorMeta } from '~/types/paginator';
import { DATA_TABLE_CLEAR_SELECTED_IDS, globalKey } from '~/utils/events';
import type { EnquiryPropertyData } from '~/types/enquiries';

export const useEnquiriesStore = defineStore('enquiries', function () {
  const { updateRouteQueryParams } = useHelpers();
  const { $showModal } = useNuxtApp();

  const { emit: busEmit } = useEventBus(globalKey);

  const loading = ref<boolean>(false);
  const toaster = useToaster();

  const records = ref<EnquiryProperty[]>([]);
  const meta = ref<PaginatorMeta>({
    current_page: 0,
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: '',
    next_page_url: '',
    path: '',
    per_page: 0,
    to: 0,
    total: 0,
  });
  const tableResults = ref<EnquiryProperty[]>([]);
  const tableMeta = ref<PaginatorMeta>({
    current_page: 0,
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: '',
    next_page_url: '',
    path: '',
    per_page: 0,
    to: 0,
    total: 0,
  });
  const filtersOpen = ref<boolean>(false);
  const selectedIds = ref<Set<number>>(new Set());

  const downloadEnquiriesStreaming = ref<boolean>(false);
  const query = reactive<EnquiriesQuery>({
    filter: {
      archive: 0,
      rooms: '',
      search: '',
      status: '',
      time: '',
      id: '',
      property: '',
    },
    page: 1,
    perPage: 30,
    sort: '-created_at',
  });
  const filtersSnapshot = ref<EnquiriesFilters>();

  /**
   * Spin through the params in the filter
   * If the filters key is not search and a value set then include it
   * Return the count of the keys
   */
  const filtersInUse = computed<number>(
    () =>
      Object.keys(
        Object.fromEntries(
          Object.entries(query.filter).filter(([key, value]) => {
            if (key === 'search') return false;
            return value.length > 0 || value !== '';
          }),
        ),
      ).length,
  );

  const possibleStatuses = ref<{ value: string; key: string }[]>([
    {
      key: 'unread',
      value: 'Unread',
    },
    {
      key: 'contacted',
      value: 'Contacted',
    },
    {
      key: 'booked',
      value: 'Viewing booked',
    },
    {
      key: 'let_agreed',
      value: 'Let Agreed',
    },
    {
      key: 'closed',
      value: 'Closed',
    },
  ]);

  const possibleTimes = ref<{ key: number | string; value: string }[]>([
    {
      key: '',
      value: 'Any',
    },
    {
      key: 7,
      value: 'Last 7 days',
    },
    {
      key: 14,
      value: 'Last 14 days',
    },
    {
      key: 30,
      value: 'Last 30 days',
    },
  ]);

  const possibleArchiveOptions = ref<{ key: number | string; value: string }[]>(
    [
      {
        key: 0,
        value: 'No',
      },
      {
        key: 1,
        value: 'Yes',
      },
    ],
  );

  const queryParams = computed(() => {
    return {
      'filter[archive]': query.filter.archive,
      'filter[id]': query.filter.id,
      'filter[property]': query.filter.property,
      'filter[rooms]': query.filter.rooms,
      'filter[search]': query.filter.search,
      'filter[status]': query.filter.status,
      'filter[time]': query.filter.time,
      page: query.page,
      perPage: query.perPage,
      sort: query.sort,
    };
  });

  async function getEnquiries(updateTable = true) {
    try {
      if (updateTable) {
        loading.value = true;
      }

      const { data } = await enquiriesApi.get(unref(queryParams));

      if (data.value) {
        records.value = data.value.data;
        meta.value = data.value?.meta;
      }

      updateRouteQueryParams(unref(queryParams));

      if (updateTable) {
        tableResults.value = data.value?.data;
        tableMeta.value = data.value?.meta;
        loading.value = false;
      }
    } catch (error) {
      loading.value = false;
    }
  }

  async function bulkUpdateStatus(
    payload: BulkUpdateStatusPayload,
    status: string = 'that',
  ) {
    if (payload.ids.length === 0) {
      toaster.add(
        'warning',
        `Selected enquiries already have ${status} status set`,
      );
      return;
    }

    const response: {
      status: {
        value: string;
      };
      data: {
        value: {
          message: string;
        };
      };
    } = await enquiriesApi.actions.status(payload);

    if (response.status.value === 'error') {
      toaster.add(
        'danger',
        'An attempt to update statuses failed, please try again',
      );
      return;
    }

    if (response.status.value === 'success') {
      tableResults.value.map((enquiry) => {
        if (!payload.ids.includes(enquiry.id)) return enquiry;
        enquiry.isRead = true;

        if ('status' in payload) {
          enquiry.status = payload.status ?? EnquiryPropertyStatus.open;
        }

        return enquiry;
      });
    }

    if (response.data?.value?.message) {
      toaster.add('success', response.data.value.message);
    }

    selectedIds.value.clear();
  }

  function getEnquiryProperty(
    id: number,
    condition?: (enquiryProperty: EnquiryProperty) => boolean,
  ): EnquiryProperty | null {
    const enquiryProperty: unknown = tableResults.value.find(
      (enquiryProperty: any) => {
        if (condition && !condition(enquiryProperty as EnquiryProperty))
          return false;
        return enquiryProperty.id === id;
      },
    );
    return enquiryProperty as EnquiryProperty | null;
  }

  function updateTableResults() {
    loading.value = true;

    // Fake loading so the results don't update too quickly
    setTimeout(() => {
      tableResults.value = records.value;
      tableMeta.value = meta.value;
      query.page = 1;
      updateRouteQueryParams(unref(queryParams));
      busEmit(DATA_TABLE_CLEAR_SELECTED_IDS, { table: 'enquiries' });
      loading.value = false;
    }, TABLE_LOADING_DELAY);
  }

  const toggleFilter = (key: string, $filter: filterTypes): void => {
    // Convert the csv back to an array, if the string is empty then return an empty array
    const statusArray: (string | number)[] = query.filter[$filter].split(',');

    if (statusArray.includes(key)) {
      query.filter[$filter] = statusArray
        .filter((status): boolean => status !== key)
        .join();
      return;
    }

    statusArray.push(key);
    query.filter[$filter] = statusArray.join();
  };

  const downloadEnquiries = async (): Promise<void> => {
    downloadEnquiriesStreaming.value = true;

    const filterTime: string = query.filter.time ?? '';

    const { data } = await useTableBlobFetch(`enquiries/download-csv`, query);

    if (data.value === null) {
      downloadEnquiriesStreaming.value = false;
      return;
    }

    const csvFileName = (): string => {
      const fileName: string =
        filterTime === ''
          ? 'all-enquiries-'
          : 'enquiries-from-last-' + filterTime + '-days-';
      return fileName + dayjs().format('YYYY-MM-DD') + '.csv';
    };

    // https://dev.to/nombrekeff/download-file-from-blob-21ho
    const downloadBlob = (data: BlobPart, fileName: string): void => {
      // Create a custom anchor element
      const link: HTMLAnchorElement = document.createElement('a');
      // Add it to the page
      document.body.appendChild(link);
      // Set the class so it's hidden
      link.classList.add('hidden');
      // Create a new blob from the data coming back from the endpoint
      const blob: Blob = new Blob([data], {
        type: 'octet/stream',
      });

      const url: string = URL.createObjectURL(blob);
      link.href = url;
      link.download = fileName;
      // link.click();
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      );

      URL.revokeObjectURL(url);

      // Remove link from body
      document.body.removeChild(link);

      downloadEnquiriesStreaming.value = false;
    };

    downloadBlob(data.value, csvFileName());
  };

  const clearAllFilters = (): void => {
    Object.assign(query.filter, {
      archive: 0,
      rooms: '',
      search: '',
      status: '',
      time: '',
      id: '',
    });
  };

  const generateEmailSubject = (
    enquiryProperty: EnquiryProperty | EnquiryPropertyData,
  ): string => {
    let subject =
      'Your enquiry for ' +
      enquiryProperty.property?.houseNo +
      ' ' +
      enquiryProperty.property?.street +
      ' via UniHomes&body=Hi ' +
      enquiryProperty.enquiry?.name.split(' ')[0] +
      ',%0d%0a%0d%0aThanks for your enquiry about ' +
      enquiryProperty.property?.houseNo +
      ' ' +
      enquiryProperty.property?.street +
      ' ' +
      enquiryProperty.property?.postcode +
      ' (from £' +
      enquiryProperty.property?.cheapestRoomPriceChecked +
      ' pppw';

    /**
     * Has the landlord got utilities markup removed?
     * If so, don't speculate on the price covering all bills
     */
    if (enquiryProperty.property?.landlordRemoveUtilityMarkup === false) {
      subject += ', including all bills';
    }

    subject +=
      ').%0d%0a%0d%0a ' +
      encodeURIComponent(enquiryProperty.property?.publicLink as string) +
      '%0d%0a%0d%0a%0d%0a';
    return subject;
  };

  const updateStatus = async (
    enquiryProperty: EnquiryProperty | EnquiryPropertyData,
    status: EnquiryPropertyStatus,
    actionConfirmed: boolean = false,
  ) => {
    if (status === EnquiryPropertyStatus.let_agreed && !actionConfirmed) {
      $showModal(ConfirmEnquiryActionModal, {
        closable: true,
        id: 'confirm-let-agreed-action',
        size: 'md',
        title: 'Are you sure?',
        message: `Setting this enquiry as 'Let agreed' will also set the property status to not live.`,
        data: {
          callback: async () =>
            await updateStatus(enquiryProperty, status, true),
        },
        confirmLabel: 'Set to not live',
      });
      return;
    }

    if (status === EnquiryPropertyStatus.closed && !actionConfirmed) {
      $showModal(ConfirmEnquiryActionModal, {
        closable: true,
        id: 'confirm-closed-action',
        size: 'md',
        title: 'Are you sure?',
        message: 'This action cannot be undone.',
        data: {
          callback: async () =>
            await updateStatus(enquiryProperty, status, true),
        },
      });
      return;
    }

    const request = await useApiFetch(
      useZiggy('agents.enquiries.update', [enquiryProperty.id]),
      {
        method: 'PATCH',
        body: {
          status,
        },
      },
    );

    if (request.status.value === 'error') {
      toaster.add('danger', request.error?.value?.data?.error);
      return false;
    }

    // update the table row
    if (request.data?.value?.newStatus === status) {
      enquiryProperty.status = status;
      enquiryProperty.isRead = true;
      return true;
    }

    return false;
  };

  function mapRouteQuery(routeQuery) {
    if (routeQuery['filter[property]']) {
      query.filter.property = routeQuery['filter[property]'];
    }

    if (routeQuery['filter[id]']) {
      query.filter.id = routeQuery['filter[id]'];
    }

    if (routeQuery['filter[search]']) {
      query.filter.search = routeQuery['filter[search]'];
    }

    if (routeQuery['filter[status]']) {
      query.filter.status = routeQuery['filter[status]'];
    }
  }

  // Only watch for updates for the filters shown in the modal
  watch(
    [() => query.filter],
    () => {
      filtersOpen.value ? getEnquiries(false) : getEnquiries(true);
    },
    { deep: true },
  );

  watch(
    [() => query.page, () => query.perPage, () => query.sort],
    async () => {
      await getEnquiries();
    },
    { deep: true },
  );

  watch(filtersOpen, () => {
    if (filtersOpen.value) {
      // Save a snapshot
      filtersSnapshot.value = Object.assign({}, query.filter);
      return;
    }

    // Manually update the table results when the filter modal is closed, if any filters have changed
    if (
      JSON.stringify(filtersSnapshot.value) !== JSON.stringify(query.filter)
    ) {
      updateTableResults();
    }
    filtersSnapshot.value = undefined;
  });

  return {
    downloadEnquiriesStreaming,
    filtersInUse,
    filtersOpen,
    filterSnapshot: filtersSnapshot,
    loading,
    meta,
    possibleArchiveOptions,
    possibleStatuses,
    possibleTimes,
    query,
    selectedIds,
    tableMeta,
    tableResults,

    bulkUpdateStatus,
    clearAllFilters,
    downloadEnquiries,
    generateEmailSubject,
    getEnquiries,
    mapRouteQuery,
    toggleFilter,
    updateStatus,
    getEnquiryProperty,
  };
});
